import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Image from 'src/components/shared/Image';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import AddFormBody from 'src/components/tutorial/AddForm';
import UpdateForm from 'src/components/tutorial/UpdateForm';
import prepareRequest from 'src/helper/prepareRequest';

const Tutorials: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [params, setParams] = useSearchParams({
    page: '1'
  });
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1
  });

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
    }
  }, [params]);

  function GetItems() {
    setIsLoading(true);

    return prepareRequest(
      {
        url: 'tutorials',
        params: params
      },
      (data) => {
        const { data: items, pagination: responsePaginate } = data?.result?.tutorials;

        setPagination(() => responsePaginate);

        setResponses(items);
      }
    ).finally(() => {
      setIsLoading(false);
    });
  }

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('image')}</th>
              <th>{t('name')}</th>
              <th>{t('category')}</th>

              <th>{t('status')}</th>
              <th>{t('latest-update')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      <Image
                        className="avatar"
                        src={item.image}
                        width={40}
                        height={40}
                        alt={`${item.title} - ${item.alt_title}`}
                      />
                    </td>
                    <td>
                      {item.title} - {item.alt_title}
                    </td>

                    <td>
                      {item.tutorial_category?.name} - {item.tutorial_category?.alt_name}
                    </td>

                    <td>
                      <ChangeStatus
                        active={!!item.is_active}
                        refetch={GetItems}
                        pathname={'tutorials/update_is_active/' + item.id}
                      />
                    </td>

                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2">
                        <UpdateForm
                          reFetching={GetItems}
                          scheme={{
                            ...item,
                            tutorial_category_id: item.tutorial_category?.id
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => (
          <>
            <AddFormBody reFetching={GetItems} />
          </>
        )}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
          }
        }}
        onNextClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page + 1 : 1).toString()
            );

            return param;
          });
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page - 1 : 1).toString()
            );

            return param;
          });
        }}
        loading={isLoading}
      />
    );
  }, [isLoading]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
    </React.Fragment>
  );
};

export default Tutorials;
