import React from 'react';

const ItemList = ({
  children,
  className,
  ...props
}: { children?: React.ReactNode } & React.HTMLAttributes<HTMLLIElement>) => (
  <li
    className={['first:pt-0 last:pb-0', className].join(' ')}
    {...props}
  >
    {children}
  </li>
);

export default ItemList;
