import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';

const Subscriptions: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [params, setParams] = useSearchParams({
    page: '1'
  });
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1
  });

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
    }
  }, [params]);

  function GetItems() {
    setIsLoading(true);

    return prepareRequest(
      {
        url: 'subscriptions',
        params: params
      },
      (data) => {
        const { data: items, pagination: responsePaginate } = data?.result?.subscriptions;

        setPagination(() => responsePaginate);

        setResponses(items);
      }
    ).finally(() => {
      setIsLoading(false);
    });
  }

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('user')}</th>
              <th>{t('plan')}</th>
              <th>{t('price')}</th>
              <th>{t('start-date')}</th>
              <th>{t('end-date')}</th>
              <th>{t('period')}</th>
              <th>{t('date')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.owner?.name} <br />
                      {item.owner?.mobile}
                    </td>
                    <td>
                      {item.package?.name} - {item.package?.alt_name}
                    </td>
                    <td>{CurrencyFormatter(item.price || 0)}</td>
                    <td>
                      <SharedTime date={item.start_date} />
                    </td>
                    <td>
                      <div className="inline-flex items-center gap-2">
                        <SharedTime date={item.end_date} />
                        {isNearlyExpire(item.end_date) && (
                          <p className="text-red-700 bg-red-100 text-xs font-medium py-1.5 px-2 rounded">
                            {t('nearly-expire')}
                          </p>
                        )}
                      </div>
                    </td>
                    <td>{t(item.period_type)}</td>

                    <td>
                      <SharedTime date={item.created_at} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => <></>}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
          }
        }}
        onNextClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page + 1 : 1).toString()
            );

            return param;
          });
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page - 1 : 1).toString()
            );

            return param;
          });
        }}
        loading={isLoading}
      />
    );
  }, [isLoading]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
    </React.Fragment>
  );
};

export function isNearlyExpire(end_date: string) {
  const endDate = new Date(end_date);
  const almostDone = Math.ceil(moment(endDate).diff(new Date(), 'days', true));
  return almostDone <= 1;
}

export default Subscriptions;
