import { Icon } from '@iconify/react';
import moment from 'moment';
import { useMemo } from 'react';

function SharedTime({ date, format = 'DD-MM-yyyy' }: { date: Date | string; format?: string }) {
  const GenerateTime = useMemo(() => {
    const validDate = moment(new Date(date)).isValid();

    if (validDate) {
      return moment(date).format(format);
    } else {
      return 'N/A';
    }
  }, [date]);

  return (
    <p
      className="inline-flex items-center gap-2 text-gray-600 group"
      title={date?.toString()}
    >
      <Icon
        className="shrink-0 group-hover:hidden"
        icon="fluent:clock-16-regular"
        width={18}
      />
      <Icon
        className="shrink-0 hidden group-hover:!block"
        icon="fluent:clock-16-filled"
        width={18}
      />
      <span className="text-sm  font-medium shrink-0">{date ? GenerateTime : 'N/A'}</span>
    </p>
  );
}

export default SharedTime;
