import { createSlice } from '@reduxjs/toolkit';

interface AddState {
  client_id: string | undefined;
  employee_id: string | undefined;
  center_id: string | undefined;
  item_ids: string[];
}

const addState: AddState = {
  client_id: undefined,
  employee_id: undefined,
  center_id: undefined,
  item_ids: []
};

const addBooking = createSlice({
  name: 'addBooking',
  initialState: addState,
  reducers: {}
});

export const {} = addBooking.actions;

export default addBooking.reducer;
