import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import InputFile from '../../shared/InputFile';
import Modal from '../../shared/Modal';
import Select from '../../shared/Select';
import { ItemsForm } from './AddForm';

export default function AddFormBody({ reFetching, scheme }: { reFetching: any; scheme: any }) {
  let render = true;
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState(scheme);
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    if (visible) {
      getCategories();
    }
  }, [visible]);

  function getCategories(q?: string) {
    prepareRequest(
      {
        url: 'tutorial_categories',
        params: {
          page: 1,
          search_key: q,
          is_active: 1
        }
      },
      (data) => {
        const result = data?.result?.tutorial_categories?.data || [];
        setCategories(() => result);
      }
    );
  }

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);
      const fd = serialize(values, { nullsAsUndefineds: true, indices: true });

      if (!(values.image instanceof File)) fd.delete('image');

      const { data } = await axiosInstance.post('tutorial_categories/update', fd);
      await reFetching();
      helper.resetForm();
      setVisible(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm({ initialValues, submitHandler });

  return (
    <>
      <button
        className="edit-btn"
        type="button"
        onClick={() => setVisible(true)}
      >
        <span>{t('edit')}</span>
      </button>
      <Modal
        title={`${scheme.name} - ${scheme.alt_name}`}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <label
              htmlFor="image"
              className="form-label"
            >
              {t('image')}
            </label>
            <InputFile
              defaultValue={values.image}
              onValueChange={function (e: any): void {
                setFieldValue('image', e);
              }}
              accept="image/*"
            />
            {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={values.name}
                name="name"
                onChange={handleChange}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                name="alt_name"
                value={values.alt_name}
                onChange={handleChange}
              />
              {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
            </div>

            <div className="form-group col-span-full">
              <label className="form-label">{t('category')}</label>
              <Select
                type={'single'}
                options={categories}
                value={values.parent_id}
                onSelect={function (value: any): any {
                  return setFieldValue('parent_id', value);
                }}
                onSearchChange={(e) => getCategories(e.target.value)}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.parent_id ? <span className="form-error">{errors?.parent_id}</span> : null}
            </div>
          </div>

          <div className="inline-flex gap-3 flex-wrap">
            <button
              className="btn-with-icon bg-primary text-white"
              type="submit"
            >
              {disabled ? (
                <Icon
                  icon="svg-spinners:3-dots-fade"
                  width={20}
                />
              ) : (
                <span>{t('submit')}</span>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
