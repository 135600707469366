import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';
import useForm from 'src/hooks/useForm';
import InputFile from '../shared/InputFile';
import Modal from '../shared/Modal';
import Select from '../shared/Select';

export interface ItemsForm {
  name: string | undefined;
  alt_name: string | undefined;
  image: File | undefined;
  desc: string | undefined;
  alt_desc: string | undefined;
  yearly_price: number | undefined;
  monthly_price: number | undefined;
  centers_count: number | undefined;
  extra_center_fees: number | undefined;
  invoices_count: number | undefined;
  center_users_count: number | undefined;
  permissions: string[];
}

export const permissions = [
  { name: 'employees', key: 'employees' },
  { name: 'clients', key: 'clients' },
  { name: 'attendance', key: 'attendance' },
  { name: 'salaries', key: 'salaries' },
  { name: 'expenses', key: 'expenses' },
  { name: 'categories', key: 'categories' },
  { name: 'prices', key: 'prices' },
  { name: 'services', key: 'services' },
  { name: 'packages-offers', key: 'packages-offers' },
  { name: 'products', key: 'products' },
  { name: 'global-settings', key: 'global-settings' },
  { name: 'center-settings', key: 'center-settings' },
  { name: 'payment-settings', key: 'payment-settings' },
  { name: 'sms-settings', key: 'sms-settings' },
  { name: 'sms-templates', key: 'sms-templates' },
  { name: 'email-templates', key: 'email-templates' },
  { name: 'logs', key: 'logs' },
  { name: 'faqs', key: 'faqs' },
  { name: 'pages', key: 'pages' }
];

export default function AddFormBody({ reFetching }: { reFetching: any }) {
  let render = true;
  const { t } = useTranslation();
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    image: undefined,
    desc: undefined,
    alt_desc: undefined,
    yearly_price: undefined,
    monthly_price: undefined,
    centers_count: undefined,
    extra_center_fees: undefined,
    invoices_count: undefined,
    center_users_count: undefined,
    permissions: []
  } satisfies ItemsForm;
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post(
        'packages/add',
        serialize(values, { nullsAsUndefineds: true, indices: true })
      );
      await reFetching();
      helper.resetForm();
      setVisible(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm({ initialValues, submitHandler });

  return (
    <>
      <button
        className="btn-with-icon !text-blue-600 !p-0"
        type="button"
        onClick={() => setVisible(true)}
      >
        <span>{t('add-new')}</span>
      </button>
      <Modal
        title={t('add-new')}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <label
              htmlFor="image"
              className="form-label"
            >
              {t('image')}
            </label>
            <InputFile
              defaultValue={values.image}
              onValueChange={function (e: any): void {
                setFieldValue('image', e);
              }}
              accept="image/*"
            />
            {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={values.name}
                name="name"
                onChange={handleChange}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                name="alt_name"
                value={values.alt_name}
                onChange={handleChange}
              />
              {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
            </div>

            <div className="form-group">
              <label className="form-label">{t('description')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={values.desc}
                name="desc"
                onChange={handleChange}
              ></textarea>
              {errors?.desc ? <span className="form-error">{errors?.desc}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-description')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={values.alt_desc}
                name="alt_desc"
                onChange={handleChange}
              ></textarea>
              {errors?.alt_desc ? <span className="form-error">{errors?.alt_desc}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('yearly-price')}</label>
              <input
                type="number"
                autoComplete="off"
                value={values.yearly_price}
                name="yearly_price"
                onChange={handleChange}
                min={0}
                step="any"
                className="form-input form-outline"
              />
              {errors?.yearly_price ? (
                <span className="form-error">{errors?.yearly_price}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('monthly-price')}</label>
              <input
                type="number"
                autoComplete="off"
                value={values.monthly_price}
                name="monthly_price"
                onChange={handleChange}
                min={0}
                step="any"
                className="form-input form-outline"
              />
              {errors?.monthly_price ? (
                <span className="form-error">{errors?.monthly_price}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('centers-count')}</label>
              <input
                type="number"
                autoComplete="off"
                value={values.centers_count}
                name="centers_count"
                min={0}
                onChange={handleChange}
                className="form-input form-outline"
              />
              {errors?.centers_count ? (
                <span className="form-error">{errors?.centers_count}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('extra-center-fees')}</label>
              <input
                type="number"
                autoComplete="off"
                value={values.extra_center_fees}
                name="extra_center_fees"
                onChange={handleChange}
                min={0}
                step="any"
                className="form-input form-outline"
              />
              {errors?.extra_center_fees ? (
                <span className="form-error">{errors?.extra_center_fees}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('users-count')}</label>
              <input
                type="number"
                autoComplete="off"
                value={values.center_users_count}
                name="center_users_count"
                onChange={handleChange}
                min={0}
                className="form-input form-outline"
              />
              {errors?.center_users_count ? (
                <span className="form-error">{errors?.center_users_count}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('invoices-count')}</label>
              <input
                type="number"
                autoComplete="off"
                value={values.invoices_count}
                name="invoices_count"
                min={0}
                onChange={handleChange}
                className="form-input form-outline"
              />
              {errors?.invoices_count ? (
                <span className="form-error">{errors?.invoices_count}</span>
              ) : null}
            </div>
            <div className="form-group col-span-full">
              <label className="form-label">{t('permissions')}</label>
              <Select
                type={'multi'}
                options={permissions.map((role) => ({
                  ...role,
                  name: t(role.name)
                }))}
                value={values.permissions}
                onSelect={function (value: any): any {
                  return setFieldValue('permissions', value);
                }}
                optionTxt={'name'}
                optionValue={'key'}
              />

              {errors?.permissions ? (
                <span className="form-error">{errors?.permissions}</span>
              ) : null}
            </div>
          </div>

          <div className="inline-flex gap-3 flex-wrap">
            <button
              className="btn-with-icon bg-primary text-white"
              type="submit"
            >
              {disabled ? (
                <Icon
                  icon="svg-spinners:3-dots-fade"
                  width={20}
                />
              ) : (
                <span>{t('submit')}</span>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
