import React from 'react';

export default function Card({
  children,
  className,
  ...others
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={[
        'w-full border border-gray-200 bg-white p-4 shadow-2xl shadow-gray-800/5 rounded-lg',
        className
      ].join(' ')}
      {...others}
    >
      {children}
    </div>
  );
}
